<template>
    <div>
        <div class="mo_space_header">
            <div class="mo_header_box">
                <a>
                    <img src="/media/images/icon_back_24.svg" @click="clickPre()">
                </a>
                <h2>비밀번호 변경</h2>
                <div></div>
            </div>
        </div>
        <section class="login_main find_pw">
            <div class="login_main_box">
                <h1>비밀번호 변경</h1>
                <input type="password" id="password" style="margin-bottom:6px;" placeholder="새로운 비밀번호 (영문, 숫자, 특수문자 포함 8~20자리)" v-model="password" @keyup="passwordCheck" />
                <div class="in_pw" style="margin-bottom:8px;" v-if="!checkPassword">
                    <p>비밀번호를 규칙에 맞게 입력해주세요.</p>
                </div>
                <input type="password" style="margin-bottom:0px;" placeholder="비밀번호 재입력" v-model="password2" @keyup="checkPass()" />
                <p id="fail_id" style="margin-top:8px;margin-bottom:0px;" v-if="checkPassEqual" >비밀번호를 동일하게 입력해주세요.</p>
                <button @click="changePassword()">확인</button>
            </div>
        </section>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import router from "@/router";
import axios from "axios";

export default defineComponent({
  data () {
    return {
      email: this.$route.params.email,
      authCode: this.$route.params.authCode,
      checkPassEqual: false,
      checkPassword: true,
      password: "",
      password2: ""
    };
  },
  methods: {
    clickPre () {
      history.back();
    },
    changePassword () {
      if (this.password.length > 0 && this.checkPassword === true) { // 비밀번호 유효성 체크
        if (this.password === this.password2) { // 입력한 비밀번호 2개가 일치
          var email = this.email;
          var password = btoa(this.password);
          var authCode = this.authCode;

          axios
            .post("/api/user/updatePass", { email, password, authCode })
            .then(res => {
              // console.log(res.data.result);
              if (res.data.result) {
                router.push({ name: "ChangePassFinished" });
              } else {
                alert("비밀번호 변경에 실패했습니다.");
              }
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          alert("새롭게 입력한 비밀번호가 일치하지 않습니다.");
        }
      } else {
        this.passwordCheck();
        document.getElementById("password").focus();
      }
    },
    passwordCheck () { // 비밀번호 유효성
      /^.*(?=.{8,20})(?=.*[!@#$%^*+=-])(?=.*[0-9])(?=.*[a-zA-Z]).*$/.test(this.password) ? this.checkPassword = true : this.checkPassword = false;
      if (this.password === '') {
        this.checkPassword = true;
      }
    },
    checkPass () { // 비밀번호 2개 일치여부 확인
      this.password === this.password2 ? this.checkPassEqual = false : this.checkPassEqual = true;
      if (this.password2 === '') {
        this.checkPassEqual = false;
      }
    }
  }
});
</script>

<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
